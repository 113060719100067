export const orderTypeList = {
    1: 'Draft',
    2: 'Quote',
    3: 'In-Product',
    4: 'Ready',
    5: 'Done',
}

export const orderTypeStyle = {
    1: { border: '1px solid #FFA500', color: '#FFA500' },
    2: { border: '1px solid #CD5C5C', color: '#CD5C5C' },
    3: { border: '1px solid #32CD32', color: '#32CD32' },
    4: { border: '1px solid #20B211', color: '#808000' },
    5: { border: '1px solid #808080', color: '#808080' },
}

export const orderShippingType = {
    SHIPPING: 'shipping',
    PICK_UP: 'pickUp',
}

export const OrderStatusList = {
    CANCELLED: '0',
    DRAFT: '1',
    QUOTE: '2',
    IN_PROGRESS: '3',
    READY: '4',
    SHIPPED: '5',
}

export const quoteTypeList = {
    1: 'New',
    2: 'Sent to confirm',
    3: 'Confirmed',
    4: 'Invoiced',
    5: 'Paid',
}

export const logTypeList = {
    1: 'Add',
    2: 'Edit',
    3: 'Delete',
    4: 'Confirm',
    5: 'Return',
}

export const printType = {
    QUOTATION: 'quotation',
    INVOICE: 'invoice',
    PACKING_LIST: 'packingList',
}

export const doorDirectionList = {
    LEFT: 'Left',
    RIGHT: 'Right',
}
