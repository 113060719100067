import {
    Button,
    Container,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
} from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'
import Grid from '@mui/material/Grid'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import AppInputPic from '../form/AppInputPic'
import {
    addNewProductColor,
    getProductColorItems,
    updateProductColor,
} from '../../store/actions/productColorAction'
import { useDispatch, useSelector } from 'react-redux'
import { DELETE_CONFIRM, formTypeList } from '../../constants/form'
import { getErrors } from '../../store/actions/errorActions'
import ItemSelect from '../form/ItemSelect'
import AppCheckbox from '../form/AppCheckbox'

const validationSchema = Yup.object().shape({
    skuNo: Yup.string().required().label('SKU'),
    // MSRPinCAD: Yup.number().required().label('MSRP in CAD'),
    // MSRPinUSD: Yup.number().label('MSRP in USD'),
    picLink: Yup.string().label('Picture Link'),
})

const ProductItemList = ({ item, setProductItems }) => {
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                setProductItems((prev) =>
                    prev.filter((item) => item.itemId !== id)
                )
            }
        },
        [setProductItems]
    )
    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{item.itemSKU}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>${item.priceCAD * item.quantity}</TableCell>
            <TableCell width={10}>
                <DeleteForeverIcon
                    onClick={() => handleDelete(item.itemId)}
                    style={{
                        color: 'red',
                        cursor: 'pointer',
                        marginRight: '20px',
                    }}
                />
            </TableCell>
        </TableRow>
    )
}

const ProductColorForm = ({
    formType,
    productId,
    doorColorId,
    setOpen,
    autoSku,
    propsValue = null,
}) => {
    const dispatch = useDispatch()
    const { searchItems } = useSelector((state) => state.productColor)
    const [productItems, setProductItems] = useState([])
    const [itemQuantity, setItemQuantity] = useState(1)
    const [selectValue, setSelectValue] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [finishedEndOption, setFinishedEndOption] = useState(() =>
        propsValue?.finishedEndOption
            ? JSON.parse(propsValue.finishedEndOption)
            : null
    )

    const suggestionCAD = useMemo(() => {
        return productItems.reduce(
            (acc, item) => acc + item.priceCAD * item.quantity,
            0
        )
    }, [productItems])

    // const suggestionUSD = useMemo(() => {
    //     return productItems.reduce(
    //         (acc, item) => acc + item.priceUSD * item.quantity,
    //         0
    //     )
    // }, [productItems])

    const handleSearchItemChange = (option) => {
        if (option) {
            const [itemSKU, priceCAD, priceUSD] = option.label.split('|')
            const values = {
                itemId: option.value,
                itemSKU,
                priceCAD: parseFloat(priceCAD),
                priceUSD: parseFloat(priceUSD),
            }
            setSelectedItem(values)
            setSelectValue(option)
        }
    }

    const handleFinishedEndItemChange = (option) => {
        if (option) {
            setFinishedEndOption(option)
        } else {
            setFinishedEndOption(null)
        }
    }

    const handleInputChange = (inputValue) => {
        const values = { search: inputValue }
        dispatch(getProductColorItems(values))
    }

    const handleAddItem = useCallback(() => {
        if (itemQuantity < 1 || !selectedItem) {
            dispatch(getErrors('Add item error'))
            return false
        }
        const itemIdExisted = productItems.some(
            (item) => item.itemId === selectedItem.itemId
        )
        if (itemIdExisted) {
            dispatch(getErrors('Item already added'))
            return false
        }
        const newItem = {
            ...selectedItem,
            quantity: itemQuantity,
        }
        setProductItems((prev) => [...prev, { ...newItem }])
        setSelectValue(null)
        setSelectedItem(null)
        setItemQuantity(1)
    }, [dispatch, itemQuantity, selectedItem, productItems])

    return (
        <Container style={{ height: '90vh', overflow: 'scroll' }}>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } PRODUCT COLOR`}
            />
            <AppForm
                initialValues={{
                    skuNo: propsValue ? propsValue.skuNo : autoSku,
                    // MSRPinCAD: propsValue ? propsValue.MSRPinCAD : '',
                    // MSRPinUSD: propsValue ? propsValue.MSRPinUSD : '',
                    picLink: propsValue ? propsValue.picLink : '',
                    online: propsValue ? propsValue.online : true,
                }}
                onSubmit={(values) => {
                    const submitValues =
                        formType === formTypeList.NEW
                            ? {
                                  ...values,
                                  productId,
                                  doorColorId,
                                  productItems,
                                  finishedEndOption,
                              }
                            : {
                                  ...propsValue,
                                  ...values,
                                  finishedEndOption,
                              }

                    formType === formTypeList.NEW
                        ? dispatch(
                              addNewProductColor(submitValues),
                              setOpen(false)
                          )
                        : dispatch(
                              updateProductColor(submitValues),
                              setOpen(false)
                          )
                }}
                validationSchema={validationSchema}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AppInput
                            name="skuNo"
                            label="SKU NO"
                            type="text"
                            inputProps={{ maxLength: 50 }}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        MSRP $
                        {formType === formTypeList.NEW
                            ? suggestionCAD
                            : propsValue.MSRPinCAD}
                    </Grid>
                    {/* <Grid item xs={6}>
                        <AppInput
                            name="MSRPinUSD"
                            label={`MSRP in USD ($${suggestionUSD})`}
                            type="number"
                            inputProps={{ maxLength: 10 }}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <AppInputPic
                            name="picLink"
                            label="Product Color Pic"
                            type="text"
                            inputProps={{ maxLength: 255 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ItemSelect
                            name="finishedEndOption"
                            value={finishedEndOption}
                            onChange={handleFinishedEndItemChange}
                            onInputChange={handleInputChange}
                            options={searchItems}
                            placeholder="Finished End Item"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AppCheckbox name="online" label="Online" />
                    </Grid>
                </Grid>
                {formType === formTypeList.NEW ? (
                    <>
                        <Divider style={{ marginBottom: '20px' }}>
                            Product Items
                        </Divider>

                        <Grid
                            container
                            spacing={2}
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            <Grid item xs={4}>
                                <ItemSelect
                                    name="searchItem"
                                    value={selectValue}
                                    onChange={handleSearchItemChange}
                                    onInputChange={handleInputChange}
                                    options={searchItems}
                                    placeholder="Item"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    name="quantity"
                                    type="number"
                                    id="quantity"
                                    label="Quantity"
                                    variant="standard"
                                    value={itemQuantity}
                                    onChange={(e) =>
                                        setItemQuantity(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    onClick={handleAddItem}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 20 }}>
                            {productItems.length > 0 && (
                                <TableContainer component={Paper}>
                                    <Table aria-label="product items table">
                                        <TableBody>
                                            {productItems?.map(
                                                (productItem) => (
                                                    <ProductItemList
                                                        item={productItem}
                                                        setProductItems={
                                                            setProductItems
                                                        }
                                                        key={productItem.itemId}
                                                    />
                                                )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                        </Grid>
                    </>
                ) : null}
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default ProductColorForm
