import { Button, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import {
    orderTypeList,
    orderTypeStyle,
    quoteTypeList,
} from '../../constants/orderType'

const OrderList = ({ order }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <TableRow>
                <TableCell>
                    <div
                        style={{
                            ...orderTypeStyle[order.orderType],
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '5px',
                            width: '80px',
                        }}
                    >
                        {orderTypeList[order.orderType]}
                    </div>
                </TableCell>
                <TableCell>
                    {order.orderType > 1
                        ? quoteTypeList[order.quoteType]
                        : null}
                </TableCell>
                <TableCell>{order.poNumber}</TableCell>
                <TableCell>{order?.invoiceNumber}</TableCell>
                <TableCell>{order.shippingType}</TableCell>
                <TableCell>{order.customer.company}</TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.ORDER,
                                    roleType.READ
                                )
                                    ? navigate(`/order/${order.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.ORDER,
                                    roleType.EDIT
                                )
                                    ? navigate(`/orderedit/${order.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Edit
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default OrderList
