import { Button, TableCell, TableRow } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { orderTypeList, orderTypeStyle } from '../../constants/orderType'

const OrderPaymentLists = ({ order }) => {
    const navigate = useNavigate()
    const totalPaid = useMemo(() => {
        if (!order || !order.orderpayments) {
            return 0
        }
        return order.orderpayments.reduce(
            (total, payment) => total + payment.amount,
            0
        )
    }, [order])
    return (
        <>
            <TableRow>
                <TableCell>
                    <div
                        style={{
                            ...orderTypeStyle[order.orderType],
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '5px',
                            width: '80px',
                        }}
                    >
                        {orderTypeList[order.orderType]}
                    </div>
                </TableCell>
                <TableCell>{order?.invoiceNumber}</TableCell>
                <TableCell>{order?.invoiceDate}</TableCell>
                <TableCell>{order?.poNumber}</TableCell>
                <TableCell>{order.customer.company}</TableCell>
                <TableCell>${order?.invoiceAmount?.toFixed(2)}</TableCell>
                <TableCell>${totalPaid?.toFixed(2)}</TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                navigate(`/orderpayment/${order.id}`)
                            }
                        >
                            Info
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default OrderPaymentLists
