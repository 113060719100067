import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import Layout from '../../components/Layout'
import { OrderStatusList, orderShippingType } from '../../constants/orderType'
import { getOneOrder } from '../../store/actions/orderAction'
import OrderItemInfo from '../../components/order/OrderItemInfo'
import { GET_ONE_ORDER } from '../../store/types'
import TotalAmount from '../../components/order/TotalAmount'
import { formTypeList, priceChangeType } from '../../constants/form'
import ChangePriceForm from '../../components/order/ChangePriceForm'
import BasicModal from '../../components/modal/BasicModal'
import OrderItemNoteForm from '../../components/order/OrderItemNoteForm'
// import DownloadInvoice from '../../components/order/DownloadInvoice'

export const ListItem = ({ left, right }) => (
    <div className="order-review-header-list">
        <div className="order-review-header-list-left">{left}:</div>
        <div className="order-review-header-list-right">{right}</div>
    </div>
)

export const OrderHeader = ({ order, orderInfo = true }) => (
    <div className="order-review-header-container">
        {orderInfo && (
            <>
                <div className="order-review-header-item">
                    <div className="order-review-header-icon">
                        <ListAltIcon size={30} />
                        <span className="order-review-header-title">
                            <h3>ORDER INFO</h3>
                        </span>
                    </div>
                    <ListItem left="PO Number" right={order?.poNumber} />
                    <ListItem
                        left="Draft Created"
                        right={moment(order?.createdAt).format('lll')}
                    />
                    <ListItem
                        left="Draft Created By"
                        right={order?.customer?.company}
                    />
                </div>
                <div className="order-review-header-divider" />
            </>
        )}
        <div className="order-review-header-item">
            <div className="order-review-header-icon">
                <LocalShippingIcon size={30} />
                <span className="order-review-header-title">
                    <h3>SHIPPING INFO</h3>
                </span>
            </div>
            {order?.shippingType === orderShippingType.PICK_UP && (
                <>
                    <ListItem left="Shipping Metodh" right="Pick Up" />
                    <ListItem left="Location" right={order?.warehouse?.name} />
                    <ListItem
                        left="Address"
                        right={`${order?.warehouse?.address}
      ${order?.warehouse?.address1 ? `, ${order?.warehouse?.address1}` : ''}, ${
                            order?.warehouse?.city
                        }, 
      ${order?.warehouse?.province}, ${order?.warehouse?.postCode} ${
                            order?.warehouse?.country
                        }`}
                    />
                </>
            )}
            {order?.shippingType === orderShippingType.SHIPPING && (
                <>
                    <ListItem left="Shipping Metodh" right="Shipping" />
                    <ListItem
                        left="Name"
                        right={`${order?.ordershippingaddress?.firstName} ${order?.ordershippingaddress?.lastName}`}
                    />
                    <ListItem
                        left="Phone"
                        right={order?.ordershippingaddress?.phone}
                    />
                    <ListItem
                        left="Email"
                        right={order?.ordershippingaddress?.email}
                    />
                    <ListItem
                        left="Address"
                        right={`${order?.ordershippingaddress?.address}
      ${
          order?.ordershippingaddress?.address1
              ? `, ${order?.ordershippingaddress?.address1}`
              : ''
      }, ${order?.ordershippingaddress?.city}, 
      ${order?.ordershippingaddress?.province}, ${
                            order?.ordershippingaddress?.postCode
                        } ${order?.ordershippingaddress?.country}`}
                    />
                </>
            )}
        </div>
    </div>
)

const CustomCardMedia = styled(CardMedia)({
    height: 50,
    objectFit: 'contain',
})

const ReviewOrderItemLists = ({ orderItem, viewType, orderId }) => {
    const finishedEndItem = useMemo(() => {
        if (orderItem?.orderitemfinishedend) {
            return orderItem.orderitemfinishedend
        }
        return null
    }, [orderItem])

    const [noteOpen, setNoteOpen] = useState(false)

    const [dialogStatus, setDialogStatus] = React.useState({
        open: false,
        editType: null,
        propValues: null,
    })

    const handleClose = useCallback(() => {
        setDialogStatus({
            open: false,
            editType: null,
            propValues: null,
        })
    }, [])

    const handleOpen = useCallback(
        (type, id) => {
            setDialogStatus({
                open: true,
                editType: type,
                propValues: { id, orderId },
            })
        },
        [orderId]
    )

    return (
        <>
            <TableRow
                className={
                    orderItem?.note ||
                    orderItem?.finishedOption ||
                    orderItem?.assemblyRequest
                        ? 'noBottomBorder'
                        : ''
                }
            >
                <Dialog open={dialogStatus?.open} onClose={handleClose}>
                    <DialogTitle>
                        UPDATE{' '}
                        {dialogStatus?.editType ===
                            priceChangeType.ITEM_PRICE && 'ITEM'}
                        {dialogStatus?.editType ===
                            priceChangeType.FINISHED_END_PRICE &&
                            'FINISHED END'}
                        {dialogStatus?.editType ===
                            priceChangeType.ASSEMBLY_FEE && 'ASSEMBLY FEE'}{' '}
                        PRICE
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Original price{' '}
                            {dialogStatus?.editType ===
                                priceChangeType.ITEM_PRICE &&
                                `$${orderItem?.basePrice}`}
                            {dialogStatus?.editType ===
                                priceChangeType.FINISHED_END_PRICE &&
                                `$${finishedEndItem?.basePrice}`}
                            {dialogStatus?.editType ===
                                priceChangeType.ASSEMBLY_FEE &&
                                `$${orderItem?.assemblyFee}`}
                        </DialogContentText>
                        <ChangePriceForm
                            type={dialogStatus?.editType}
                            propValues={dialogStatus?.propValues}
                            handleClose={handleClose}
                        />
                    </DialogContent>
                </Dialog>
                <TableCell>{orderItem?.quantity}</TableCell>
                <TableCell component="th" scope="row">
                    {orderItem.skuNo}
                </TableCell>
                <TableCell>
                    {orderItem.isItem
                        ? 'Item'
                        : orderItem?.productcolor?.product?.name}
                </TableCell>
                <TableCell>
                    {orderItem?.productcolor?.product?.doorDirection
                        ? orderItem?.doorDirection
                        : ''}
                </TableCell>
                <TableCell>
                    {orderItem?.productcolor?.finishedEnd
                        ? orderItem.finishedOption
                        : ''}
                </TableCell>
                <TableCell
                    style={
                        viewType === formTypeList.EDIT
                            ? { cursor: 'pointer' }
                            : null
                    }
                    onClick={() =>
                        viewType === formTypeList.EDIT
                            ? handleOpen(
                                  priceChangeType.ITEM_PRICE,
                                  orderItem.id
                              )
                            : null
                    }
                >
                    ${orderItem?.price?.toFixed(2)}
                </TableCell>
                <TableCell>
                    {orderItem?.price !== orderItem?.basePrice && (
                        <span
                            style={{
                                color: 'red',
                                textDecorationLine: 'line-through',
                            }}
                        >
                            ${orderItem?.basePrice?.toFixed(2)}
                        </span>
                    )}
                </TableCell>
                <TableCell>
                    ${(orderItem.price * orderItem.quantity).toFixed(2)}
                </TableCell>
            </TableRow>
            {finishedEndItem && (
                <TableRow
                    className={
                        orderItem?.note || orderItem?.assemblyRequest
                            ? 'noBottomBorder'
                            : ''
                    }
                >
                    <TableCell>{finishedEndItem?.quantity}</TableCell>
                    <TableCell component="th" scope="row">
                        {finishedEndItem?.item?.itemSKU}
                    </TableCell>
                    <TableCell>{finishedEndItem?.description}</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell
                        style={
                            viewType === formTypeList.EDIT
                                ? { cursor: 'pointer' }
                                : null
                        }
                        onClick={() =>
                            viewType === formTypeList.EDIT
                                ? handleOpen(
                                      priceChangeType.FINISHED_END_PRICE,
                                      finishedEndItem?.id
                                  )
                                : null
                        }
                    >
                        ${finishedEndItem?.price?.toFixed(2)}
                    </TableCell>
                    <TableCell>
                        {finishedEndItem?.price !==
                            finishedEndItem?.basePrice && (
                            <span
                                style={{
                                    color: 'red',
                                    textDecorationLine: 'line-through',
                                }}
                            >
                                ${finishedEndItem?.basePrice?.toFixed(2)}
                            </span>
                        )}
                    </TableCell>
                    <TableCell>
                        $
                        {(
                            finishedEndItem.price * finishedEndItem.quantity
                        ).toFixed(2)}
                    </TableCell>
                </TableRow>
            )}

            {orderItem?.assemblyRequest && (
                <TableRow className={orderItem?.note ? 'noBottomBorder' : ''}>
                    <TableCell>{orderItem?.quantity}</TableCell>
                    <TableCell component="th" scope="row">
                        Assembly Fee
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell
                        style={
                            viewType === formTypeList.EDIT
                                ? { cursor: 'pointer' }
                                : null
                        }
                        onClick={() =>
                            viewType === formTypeList.EDIT
                                ? handleOpen(
                                      priceChangeType.ASSEMBLY_FEE,
                                      orderItem.id
                                  )
                                : null
                        }
                    >
                        ${orderItem?.assemblyFee?.toFixed(2)}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                        $
                        {(orderItem?.assemblyFee * orderItem.quantity).toFixed(
                            2
                        )}
                    </TableCell>
                </TableRow>
            )}

            {orderItem?.note && (
                <>
                    <TableRow
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                        <TableCell colSpan={8}>
                            <div>
                                <div className="dot-border">
                                    {orderItem.note}
                                </div>
                                {viewType === formTypeList.EDIT && (
                                    <>
                                        <BasicModal
                                            open={noteOpen}
                                            setOpen={setNoteOpen}
                                        >
                                            <OrderItemNoteForm
                                                propValues={{
                                                    id: orderItem.id,
                                                    note: orderItem?.note,
                                                    orderId,
                                                }}
                                                handleClose={setNoteOpen}
                                            />
                                        </BasicModal>
                                        <div style={{ marginTop: '10px' }}>
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    setNoteOpen(true)
                                                }
                                                variant="outlined"
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    )
}

export const ReviewOrderStyle = ({ orderStyle, viewType, orderId }) => (
    <Card className="order-item-card">
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <CustomCardMedia
                    component="img"
                    image={
                        orderStyle?.doorcolor?.picLink ||
                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                    }
                    alt="order pic"
                />
            </Grid>
            <Grid item xs={11}>
                <CardContent>
                    <h3>
                        {orderStyle?.styleName} ({orderStyle?.doorcolor?.name})
                    </h3>
                </CardContent>
            </Grid>
        </Grid>
        {orderStyle?.orderitems?.length > 0 && (
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Qty</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Hinge Side</TableCell>
                                <TableCell>Finished End</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Base Price</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderStyle?.orderitems?.map((orderItem) => (
                                <ReviewOrderItemLists
                                    orderItem={orderItem}
                                    key={orderItem.id}
                                    viewType={viewType}
                                    orderId={orderId}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        )}
    </Card>
)

export const orderPageList = [
    { id: 1, title: 'Order List' },
    { id: 2, title: 'Order Items' },
]

const Order = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { order } = useSelector((state) => state.order)
    const [orderPage, setOrderPage] = useState(1)

    useEffect(() => {
        if (id) dispatch(getOneOrder(id))
        return () => {
            dispatch({ type: GET_ONE_ORDER, payload: null })
        }
    }, [dispatch, id])

    return (
        <Layout>
            <Container>
                <div style={{ marginTop: '50px' }} />
                <div className="order-products-box">
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={() => navigate(-1)}
                        style={{ marginBottom: '10px' }}
                    >
                        Back
                    </Button>
                </div>
                {order && (
                    <>
                        <div className="order-products-box">
                            {order?.quoteType < 4 ? (
                                <div
                                    style={{
                                        marginBottom: '10px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() =>
                                            navigate(`/printquotation/${id}`)
                                        }
                                    >
                                        Print Quotation
                                    </Button>
                                </div>
                            ) : null}
                            {order?.invoiceNo &&
                            order?.orderType !== OrderStatusList.DRAFT &&
                            order?.quoteType > 3 ? (
                                <div
                                    style={{
                                        marginBottom: '10px',
                                        marginTop: '10px',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() =>
                                            navigate(`/printinvoice/${id}`)
                                        }
                                    >
                                        Print Invoice
                                    </Button>
                                </div>
                            ) : null}
                            <OrderHeader order={order} />
                            <div style={{ marginTop: '30px' }} />
                            {orderPageList.map((list) => (
                                <Button
                                    variant={
                                        list.id === orderPage
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    color="primary"
                                    onClick={() => setOrderPage(list.id)}
                                    key={list.id}
                                    style={{ marginRight: '10px' }}
                                >
                                    {list.title}
                                </Button>
                            ))}
                            <div style={{ marginTop: '30px' }} />
                            {orderPage === 1 && (
                                <>
                                    <div className="order-product-items">
                                        {order?.orderstyles?.map(
                                            (orderStyle) => (
                                                <ReviewOrderStyle
                                                    key={orderStyle.id}
                                                    orderStyle={orderStyle}
                                                    viewType={formTypeList.READ}
                                                    orderId={id}
                                                />
                                            )
                                        )}
                                    </div>
                                    <TotalAmount order={order} />
                                </>
                            )}
                            {orderPage === 2 && <OrderItemInfo order={order} />}
                        </div>
                    </>
                )}
            </Container>
        </Layout>
    )
}

export default Order
