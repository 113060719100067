import { Button, Container, Grid } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { formTypeList } from '../../constants/form'

const ItemContent = ({ item }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <Container>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Card sx={{ width: '100%' }}>
                <CardMedia
                    sx={{ height: 500 }}
                    image={
                        item?.picLink
                            ? item.picLink
                            : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                    }
                    title="item pic"
                />
                <CardContent>
                    <Typography gutterBottom variant="h4" component="div">
                        {item.itemSKU}
                    </Typography>

                    <Typography gutterBottom variant="h5" component="div">
                        {item.itemName}
                    </Typography>
                    <br />
                    <Typography variant="body" color="text.primary">
                        Package: {item.packageLong} L x {item.packageWidth} W x{' '}
                        {item.packageHeight} H
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body1" color="text.primary">
                        Volume: {item.volume}
                    </Typography>
                    <br />
                    <Typography variant="body" color="text.primary">
                        Weight: {item.weight} {item.weightUnit}
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body1" color="text.primary">
                        Barcode: {item.barcode}
                    </Typography>
                    <br />
                    <Typography variant="body1" color="text.primary">
                        MSRP: ${item.MSRPinCAD}
                    </Typography>
                    <br />
                    {/* <Typography variant="body1" color="text.primary">
                        MSRP in USD: ${item.MSRPinUSD}
                    </Typography>
                    <br /> */}
                    <Typography variant="body1" color="text.primary">
                        Stock: {item.stock}
                    </Typography>
                    <br />
                    <Typography variant="body1" color="text.primary">
                        Alert Stock: {item.alertStock}
                    </Typography>
                    <br />
                    <Typography variant="body1" color="text.primary">
                        Auto Order Qty: {item.autoOrderQty}
                    </Typography>
                    <br />
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        style={{ whiteSpace: 'pre-line', marginTop: '20px' }}
                    >
                        {item.description}
                    </Typography>
                </CardContent>
            </Card>
            {item?.warehousemaps?.length > 0 && (
                <div>
                    <Typography gutterBottom variant="h6" component="div">
                        Stock Warehouse Location
                    </Typography>
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Grid
                            style={{
                                backgroundColor: '#c0c0c0',
                                padding: '5px',
                            }}
                            container
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                Warehouse
                            </Grid>
                            <Grid item xs={6}>
                                Location
                            </Grid>
                        </Grid>
                        {item?.warehousemaps?.map((location) => (
                            <Grid
                                key={location.id}
                                style={{
                                    padding: '5px',
                                }}
                                container
                                spacing={2}
                            >
                                <Grid item xs={6}>
                                    {location.warehouse.name}
                                </Grid>
                                <Grid item xs={6}>
                                    {location.location}
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                </div>
            )}
            <Typography gutterBottom variant="h6" component="div">
                Suppliers
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 128,
                        height: 128,
                    },
                }}
            >
                {item?.suppliers?.map((supplier) => (
                    <Paper
                        key={supplier.id}
                        className="supplier-paper"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.SUPPLIER,
                                roleType.READ
                            )
                                ? navigate(
                                      `/supplier/${supplier.id}/${formTypeList.READ}`
                                  )
                                : alert('Not Permit User')
                        }
                    >
                        {supplier.name}
                    </Paper>
                ))}
            </Box>
        </Container>
    )
}

export default ItemContent
