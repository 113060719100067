import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import PagnationComponent from '../components/PagnationComponent'
import { Container, Typography } from '@mui/material'
import { CHANGE_LOGS_PAGE } from '../store/types'
import SearchLogs from '../components/logs/SearchLogs'
import LogsInfo from '../components/logs/LogsInfo'
import { getAllLogs } from '../store/actions/logAction'

const Logs = () => {
    const dispatch = useDispatch()
    const logState = useSelector((state) => state.logs)
    const {
        logs,
        pages,
        page,
        count,
        keyword,
        searchCategory,
        searchFromDate,
        searchToDate,
    } = logState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(
            getAllLogs(
                keyword,
                pageNumber,
                searchCategory,
                searchFromDate,
                searchToDate
            )
        )
    }, [
        dispatch,
        keyword,
        pageNumber,
        searchCategory,
        searchFromDate,
        searchToDate,
    ])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_LOGS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <SearchLogs displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {logs && <LogsInfo logs={logs} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Logs
