import request from '../../config/request'
import modifyOrderItemOption from '../../utils/modifyOrderItemDoorDirection'
import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_ORDERS,
    GET_CATEGORIES,
    GET_NEW_QUOTE_COUNT,
    GET_ONE_ORDER,
} from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllOrders =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `order/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_ORDERS,
                    payload: {
                        orders: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getNewOrderCount = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.get(`order/newordercount`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_NEW_QUOTE_COUNT,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.get(`order/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_ORDER,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getCategories = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        const res = await request.get(`orderadmin/category`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_CATEGORIES,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateOrderItemPrice = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(
            `order/updateitemprice/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(values.orderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateOrderItemAssemblyFee =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `order/updateassemblyfee/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(values.orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateOrderFinishedEndPrice =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `order/updateitemfinishedendprice/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(values.orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateOrderDiscount = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(
            `order/updatediscount/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(values.id))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateOrderDeliveryCost =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `order/updatedeliverycost/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(values.id))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateOrderItemNote = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(
            `order/adminedititemnote/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(values.orderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addOrderTax = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.post(`order/addtax`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(values.orderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteOrderTax = (id, orderId) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.delete(`order/deletetax/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
            params: { orderId },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(orderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const returnOrderToDraft = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(`order/returnordertodraft/${id}`, id, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(id))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const sendToApproval = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(`order/sendforapproval/${id}`, id, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(id))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const confirmOrder = (id, order) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(
            `order/confirmorder/${id}`,
            { orderData: order },
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(id))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const createInvoice = (id, values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(`order/createinvoice/${id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(id))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addOrderStyle =
    (doorcolorId, orderId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const values = { orderId, doorcolorId }
            const res = await request.post(`orderadmin/addorderstyle`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateOrderStyleName =
    (values, orderId) => async (dispatch, getState) => {
        console.log('called')
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `orderadmin/updateorderstylename/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const deleteOrderStyle = (id, orderId) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.delete(`orderadmin/deleteorderstyle/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(orderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateOrderStyle =
    (values, orderId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `orderadmin/updateorderstyle/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const addOrderItem =
    (values, orderStyleId, orderId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const sendValues = { ...values, orderStyleId }
            const res = await request.post(
                `orderadmin/addorderitem`,
                sendValues,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const addOrderSingleItem =
    (values, orderStyleId, orderId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const sendValues = { ...values, orderStyleId }
            const res = await request.post(
                `orderadmin/addordersingleitem`,
                sendValues,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneOrder(orderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const deleteOrderItem = (id, orderId) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.delete(`orderadmin/deleteorderitem/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(getOneOrder(orderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateDoorDirection = (values) => async (dispatch, getState) => {
    const { doorDirection, orderItemId, orderStyleId } = values
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            order: { order },
        } = getState()

        const res = await request.put(
            `orderadmin/updatedoordirection/${orderItemId}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        const tempOrder = modifyOrderItemOption(
            order,
            orderStyleId,
            orderItemId,
            'doorDirection',
            doorDirection
        )

        dispatch({
            type: GET_ONE_ORDER,
            payload: tempOrder,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateFinishedOption = (values) => async (dispatch, getState) => {
    const { finishedOption, orderItemId, orderStyleId } = values
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            order: { order },
        } = getState()

        const res = await request.put(
            `orderadmin/updatefinishedoption/${orderItemId}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        const tempOrder = modifyOrderItemOption(
            order,
            orderStyleId,
            orderItemId,
            'finishedOption',
            finishedOption
        )

        dispatch({
            type: GET_ONE_ORDER,
            payload: tempOrder,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateItemQty = (values) => async (dispatch, getState) => {
    const { quantity, orderItemId, orderStyleId } = values
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            order: { order },
        } = getState()

        const res = await request.put(
            `orderadmin/updateitemqty/${orderItemId}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        const tempOrder = modifyOrderItemOption(
            order,
            orderStyleId,
            orderItemId,
            'quantity',
            quantity
        )

        dispatch({
            type: GET_ONE_ORDER,
            payload: tempOrder,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateItemAssembly = (values) => async (dispatch, getState) => {
    const { assemblyRequest, orderItemId, orderStyleId } = values
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            order: { order },
        } = getState()

        const res = await request.put(
            `orderadmin/updateitemassembly/${orderItemId}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        const tempOrder = modifyOrderItemOption(
            order,
            orderStyleId,
            orderItemId,
            'assemblyRequest',
            assemblyRequest
        )

        dispatch({
            type: GET_ONE_ORDER,
            payload: tempOrder,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateItemSort = (values) => async (dispatch, getState) => {
    const {
        reSort: { startIndex, endIndex },
        orderStyleId,
    } = values
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            order: { order },
        } = getState()

        const orderStyle = order.orderstyles.find(
            (style) => style.id === orderStyleId
        )

        if (orderStyle) {
            const [removedItem] = orderStyle.orderitems.splice(startIndex, 1)
            orderStyle.orderitems.splice(endIndex, 0, removedItem)
        }

        const newSort = orderStyle.orderitems?.map((orderItem, index) => ({
            id: orderItem.id,
            sort: index + 1,
        }))

        const payloads = { newSort }

        const res = await request.put(
            `orderadmin/updateorderitemsort`,
            payloads,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))

        const newOrder = [...order]

        dispatch({
            type: GET_ONE_ORDER,
            payload: newOrder,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addItemNote = (values) => async (dispatch, getState) => {
    const { note, orderItemId, orderStyleId } = values
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            order: { order },
        } = getState()

        const res = await request.post(
            `orderadmin/additemnote/${orderItemId}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        const tempOrder = modifyOrderItemOption(
            order,
            orderStyleId,
            orderItemId,
            'note',
            note
        )

        dispatch({
            type: GET_ONE_ORDER,
            payload: tempOrder,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
