import {
    CHANGE_ORDER_PAYMENTS_PAGE,
    EDIT_ORDER_PAYMENT,
    GET_ALL_ORDER_PAYMENTS,
    GET_ONE_ORDER_PAYMENT,
    SEARCH_ORDER_PAYMENTS,
} from '../types'

const initialState = {
    orderPayments: null,
    orderPayment: null,
    editOrderPayment: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export default function orderPaymentReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ORDER_PAYMENTS:
            return {
                ...state,
                orderPayments: payload.orderPayments,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                orderPayment: null,
            }
        case SEARCH_ORDER_PAYMENTS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_ORDER_PAYMENTS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_ORDER_PAYMENT:
            return {
                ...state,
                orderPayment: payload,
                editOrderPayment: null,
            }
        case EDIT_ORDER_PAYMENT:
            return {
                ...state,
                editOrderPayment: payload,
            }
        default:
            return state
    }
}
