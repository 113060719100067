export const SHOW_LOADING = 'SHOW_LOADING'
export const USER_LOGOIN_SUCCESS = 'USER_LOGOIN_SUCCESS'
export const USER_UPDATE_TOKEN = 'USER_UPDATE_TOKEN'
export const GET_ERRORS = 'GET_ERRORS'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const USER_LOGOUT = 'USER_LOGOUT'
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const SEARCH_USERS = 'SEARCH_USERS'
export const CHANGE_USERS_PAGE = 'CHANGE_USERS_PAGE'
export const GET_ONE_USER = 'GET_ONE_USER'
export const UPDATE_MODE = 'UPDATE_MODE'
export const UPDATE_USER_OPEN = 'UPDATE_USER_OPEN'
export const UPDATE_SETTING_OPEN = 'UPDATE_SETTING_OPEN'
export const GET_ROLES_LIST = 'GET_ROLES_LIST'

export const GET_ALL_SUPPLIERS = 'GET_ALL_SUPPLIERS'
export const SEARCH_SUPPLIERS = 'SEARCH_SUPPLIERS'
export const CHANGE_SUPPLIERS_PAGE = 'CHANGE_SUPPLIERS_PAGE'
export const GET_ONE_SUPPLIER = 'GET_ONE_SUPPLIER'
export const GET_ONE_SUPPLIER_CONTACT = 'GET_ONE_SUPPLIER_CONTACT'

export const GET_ALL_LOGS = 'GET_ALL_LOGS'
export const SEARCH_LOGS = 'SEARCH_LOGS'
export const CHANGE_LOGS_PAGE = 'CHANGE_LOGS_PAGE'

export const GET_ALL_ITEMS = 'GET_ALL_ITEMS'
export const GET_ONE_ITEM = 'GET_ONE_ITEM'
export const SEARCH_ITEMS = 'SEARCH_ITEMS'
export const CHANGE_ITEMS_PAGE = 'CHANGE_ITEMS_PAGE'

export const GET_ALL_PRODUCT_CATEGORY = 'GET_ALL_PRODUCT_CATEGORY'
export const GET_ONE_PRODUCT_CATEGORY = 'GET_ONE_PRODUCT_CATEGORY'
export const GET_ONE_PRODUCT_STYLE = 'GET_ONE_PRODUCT_STYLE'

export const GET_ALL_DOOR_CATEGORY = 'GET_ALL_DOOR_CATEGORY'
export const GET_ONE_DOOR_CATEGORY = 'GET_ONE_DOOR_CATEGORY'
export const GET_ONE_DOOR_STYLE = 'GET_ONE_DOOR_STYLE'
export const GET_ONE_DOOR_COLOR = 'GET_ONE_DOOR_COLOR'

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ONE_PRODUCT = 'GET_ONE_PRODUCT'
export const CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const CHANGE_PRODUCTS_PAGE = 'CHANGE_PRODUCTS_PAGE'
export const GET_PRODUCT_DOOR_COLORS = 'GET_PRODUCT_DOOR_COLORS'

export const GET_ALL_PRODUCT_COLORS = 'GET_ALL_PRODUCT_COLORS'
export const GET_ONE_PRODUCT_COLOR = 'GET_ONE_PRODUCT_COLOR'
export const GET_PRODUCT_COLOR_ITEMS = 'GET_PRODUCT_COLOR_ITEMS'
export const SEARCH_PRODUCT_COLORS = 'SEARCH_PRODUCT_COLORS'
export const CHANGE_PRODUCT_COLORS_PAGE = 'CHANGE_PRODUCT_COLORS_PAGE'
export const GET_ONE_PRODUCT_ITEM = 'GET_ONE_PRODUCT_ITEM'

export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS'
export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS'
export const CHANGE_CUSTOMERS_PAGE = 'CHANGE_CUSTOMERS_PAGE'
export const GET_ONE_CUSTOMER = 'GET_ONE_CUSTOMER'
export const GET_USER_SELECT_LIST = 'GET_USER_SELECT_LIST'

export const GET_ALL_WAREHOUSES = 'GET_ALL_WAREHOUSES'
export const GET_ONE_WAREHOUSE = 'GET_ONE_WAREHOUSE'

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ONE_ORDER = 'GET_ONE_ORDER'
export const SEARCH_ORDERS = 'SEARCH_ORDERS'
export const CHANGE_ORDERS_PAGE = 'CHANGE_ORDERS_PAGE'

export const GET_ALL_BASE_TAXES = 'GET_ALL_BASE_TAXES'
export const GET_ONE_BASE_TAX = 'GET_ALL_BASE_TAX'
export const GET_NEW_QUOTE_COUNT = 'GET_NEW_QUOTE_COUNT'

export const GET_ALL_PURCHASE_ORDERS = 'GET_ALL_PURCHASE_ORDERS'
export const SEARCH_PURCHASE_ORDERS = 'SEARCH_PURCHASE_ORDERS'
export const CHANGE_PURCHASE_ORDERS_PAGE = 'CHANGE_PURCHASE_ORDERS_PAGE'
export const GET_ONE_PURCHASE_ORDER = 'GET_ONE_PURCHASE_ORDER'
export const GET_ALL_ITEM_SKU = 'GET_ALL_ITEM_SKU'
export const GET_ALL_PURCHASE_ORDER_SUPPLIERS =
    'GET_ALL_PURCHASE_ORDER_SUPPLIERS'

export const GET_ALL_SHIPMENTS = 'GET_ALL_SHIPMENTS'
export const SEARCH_SHIPMENTS = 'SEARCH_SHIPMENTS'
export const CHANGE_SHIPMENTS_PAGE = 'CHANGE_SHIPMENTS_PAGE'
export const GET_ONE_SHIPMENT = 'GET_ONE_SHIPMENT'

export const GET_ALL_DEFECTIVE_ITEMS = 'GET_ALL_DEFECTIVE_ITEMS'
export const GET_DEFECTIVE_ITEM_SEARCH_ITEMS = 'GET_DEFECTIVE_ITEM_SEARCH_ITEMS'
export const SEARCH_DEFECTIVE_ITEMS = 'SEARCH_DEFECTIVE_ITEMS'
export const CHANGE_DEFECTIVE_ITEMS_PAGE = 'CHANGE_DEFECTIVE_ITEMS_PAGE'
export const GET_ALL_DEFECTIVE_ITEM_SUPPLIERS =
    'GET_ALL_DEFECTIVE_ITEM_SUPPLIERS'

export const GET_ALL_ORDER_PAYMENTS = 'GET_ALL_ORDER_PAYMENTS'
export const GET_ONE_ORDER_PAYMENT = 'GET_ONE_ORDER_PAYMENT'
export const EDIT_ORDER_PAYMENT = 'EDIT_ORDER_PAYMENT'
export const SEARCH_ORDER_PAYMENTS = 'SEARCH_ORDER_PAYMENTS'
export const CHANGE_ORDER_PAYMENTS_PAGE = 'CHANGE_ORDER_PAYMENTS_PAGE'

export const GET_ALL_ORDER_SHIPMENTS = 'GET_ALL_ORDER_SHIPMENTS'
export const GET_ONE_ORDER_SHIPMENT = 'GET_ONE_ORDER_SHIPMENT'
export const SEARCH_ORDER_SHIPMENTS = 'SEARCH_ORDER_SHIPMENTS'
export const CHANGE_ORDER_SHIPMENTS_PAGE = 'CHANGE_ORDER_SHIPMENTS_PAGE'

export const GET_ONE_WAREHOUSE_MAP = 'GET_ONE_WAREHOUSE_MAP'
export const GET_ALL_WAREHOUSE_MAPS_ITEMS = 'GET_ALL_WAREHOUSE_MAPS_ITEMS'

export const GET_ALL_ITEM_RECORDS = 'GET_ALL_ITEM_RECORDS'
export const CHANGE_ITEM_RECORDS_PAGE = 'CHANGE_ITEM_RECORDS_PAGE'
export const GET_CATEGORIES = 'GET_CATEGORIES'
