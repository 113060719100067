import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import PagnationComponent from '../../components/PagnationComponent'
import { Container, Typography } from '@mui/material'
import { CHANGE_ORDER_PAYMENTS_PAGE } from '../../store/types'
import { getAllOrderPayments } from '../../store/actions/orderPaymentAction'
import SearchOrderPayment from '../../components/orderPayment/SearchOrderPayment'
import OrderPaymentInfos from '../../components/orderPayment/OrderPaymentInfos'

const OrderPayments = () => {
    const dispatch = useDispatch()

    const { orderPayments, pages, page, count, keyword } = useSelector(
        (state) => state.orderPayment
    )

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllOrderPayments(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ORDER_PAYMENTS_PAGE, payload: page })
        },
        [dispatch]
    )

    return (
        <Layout>
            <Container>
                <SearchOrderPayment displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {orderPayments && <OrderPaymentInfos orders={orderPayments} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default OrderPayments
