import React from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Container } from '@mui/material'
import Grid from '@mui/material/Grid'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import { addNewItem, updateItem } from '../../store/actions/itemAction'
import AppSelect from '../form/AppSelect'
import selectList from '../../constants/selectList.json'
import AppReactSelect from '../form/AppReactSelect'
import AppInputPic from '../form/AppInputPic'

const validationSchema = Yup.object().shape({
    itemSKU: Yup.string().required().label('SKU'),
    itemName: Yup.string().required().label('Item Name'),
    packageLong: Yup.number().required().label('Package Long'),
    packageWidth: Yup.number().required().label('Package Width'),
    packageHeight: Yup.number().required().label('Package Height'),
    volume: Yup.number().required().label('Volume'),
    weight: Yup.number().required().label('Item Weight'),
    weightUnit: Yup.string().required().label('Weight Unit'),
    barcode: Yup.string().label('Barcode'),
    MSRPinCAD: Yup.number().label('MSRP'),
    alertStock: Yup.number().label('Alert Stock'),
    autoOrderQty: Yup.number().label('Auto Order Qty'),
    // MSRPinUSD: Yup.number().label('MSRP in USD'),
    description: Yup.string().label('Description'),
    picLink: Yup.string().label('Picture Link'),
    suppliers: Yup.array()
        .required()
        .min(1)
        .label('At least one supplier is required'),
})

const ItemForm = ({ formType, propsValue = null }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const itemState = useSelector((state) => state.item)
    const { suppliers, warehouseMaps } = itemState

    return (
        <>
            <Container>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Header
                    title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                        formType === formTypeList.EDIT ? 'EDIT' : ''
                    } ITEM`}
                />
                <AppForm
                    initialValues={{
                        itemSKU: propsValue ? propsValue.itemSKU : '',
                        itemName: propsValue ? propsValue.itemName : '',
                        packageLong: propsValue ? propsValue.packageLong : '',
                        packageWidth: propsValue ? propsValue.packageWidth : '',
                        packageHeight: propsValue
                            ? propsValue.packageHeight
                            : '',
                        volume: propsValue ? propsValue.volume : '',
                        weight: propsValue ? propsValue.weight : '',
                        weightUnit: propsValue
                            ? propsValue.weightUnit
                            : selectList.weightUnit.default,
                        barcode: propsValue ? propsValue.barcode : '',
                        MSRPinCAD: propsValue ? propsValue.MSRPinCAD : '',
                        // MSRPinUSD: propsValue ? propsValue.MSRPinUSD : '',
                        alertStock: propsValue ? propsValue.alertStock : 0,
                        autoOrderQty: propsValue ? propsValue.autoOrderQty : 0,
                        description: propsValue ? propsValue.description : '',
                        picLink: propsValue ? propsValue.picLink : '',
                        suppliers: propsValue
                            ? propsValue?.suppliers?.map((item) => item.id)
                            : [],
                        warehousemaps: propsValue
                            ? propsValue.warehousemaps?.map(
                                  (warehouseMap) => warehouseMap.id
                              )
                            : [],
                    }}
                    onSubmit={(values) => {
                        const submitValues =
                            formType === formTypeList.NEW
                                ? values
                                : { ...propsValue, ...values }
                        formType === formTypeList.NEW
                            ? dispatch(addNewItem(submitValues, navigate))
                            : dispatch(updateItem(submitValues, navigate))
                    }}
                    validationSchema={validationSchema}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <AppInput
                                name="itemSKU"
                                label="Item SKU"
                                type="text"
                                inputProps={{
                                    maxLength: 50,
                                    // readOnly: !!propsValue,
                                }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AppInput
                                name="itemName"
                                label="Item Name"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="packageLong"
                                label="Package Long"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="packageWidth"
                                label="Package Width"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="packageHeight"
                                label="Package Height"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="volume"
                                label="Volume"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="weight"
                                label="Weight"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppSelect
                                name="weightUnit"
                                label="Weight Unit"
                                options={selectList.weightUnit.list}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInput
                                name="barcode"
                                label="Barcode"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInput
                                name="MSRPinCAD"
                                label="MSRP"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AppInput
                                name="alertStock"
                                label="Alert Stock"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AppInput
                                name="autoOrderQty"
                                label="Auto Order Qty"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInput
                                name="description"
                                label="Description"
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppReactSelect
                                options={suppliers}
                                name="suppliers"
                                label="Supplier"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppReactSelect
                                options={warehouseMaps}
                                name="warehousemaps"
                                label="Stock Warehouse Location"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInputPic
                                name="picLink"
                                label="Item Pic"
                                type="text"
                                inputProps={{ maxLength: 255 }}
                            />
                        </Grid>
                    </Grid>
                    <SubmitButton title="Submit" />
                </AppForm>
            </Container>
        </>
    )
}

export default ItemForm
