import { Button } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import BasicModal from '../modal/BasicModal'
import DiscountForm from './DiscountForm'
import TaxesForm from './TaxesForm'
import {
    confirmOrder,
    returnOrderToDraft,
    sendToApproval,
} from '../../store/actions/orderAction'
import { OrderStatusList } from '../../constants/orderType'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'

const returnDraftList = [
    OrderStatusList.QUOTE,
    OrderStatusList.IN_PROGRESS,
    OrderStatusList.READY,
]

const OrderEditList = ({ order }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [discountOpen, setDiscountOpen] = useState(false)
    const [taxesOpen, setTaxesOpen] = useState(false)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const propValues = useMemo(() => {
        const values = {
            id: order?.id,
            deliveryCost: order?.deliveryCost,
            discountPercentage: order?.discountPercentage,
        }
        return values
    }, [order])

    return (
        <>
            <BasicModal open={discountOpen} setOpen={setDiscountOpen}>
                <DiscountForm
                    propValues={propValues}
                    handleClose={setDiscountOpen}
                />
            </BasicModal>
            <BasicModal open={taxesOpen} setOpen={setTaxesOpen}>
                <TaxesForm />
            </BasicModal>
            <div className="order-edit-list-container">
                <div className="order-edit-list-box">
                    {order?.orderType === OrderStatusList.QUOTE &&
                        order?.quoteType === 1 && (
                            <>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="success"
                                    onClick={() => setDiscountOpen(true)}
                                >
                                    Edit Discount & Delivery
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setTaxesOpen(true)}
                                >
                                    Edit Order Taxes
                                </Button>

                                {checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.ORDER,
                                    roleType.APPROVAL
                                ) && (
                                    <>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="info"
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Want to Send for approval'
                                                    )
                                                ) {
                                                    dispatch(
                                                        sendToApproval(order.id)
                                                    )
                                                }
                                            }}
                                        >
                                            Send for approval
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="success"
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Confirm Order?'
                                                    )
                                                ) {
                                                    dispatch(
                                                        confirmOrder(
                                                            order.id,
                                                            order
                                                        )
                                                    )
                                                }
                                            }}
                                        >
                                            Confirm Order
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() =>
                                                navigate(
                                                    `/productorders/${order.id}`
                                                )
                                            }
                                        >
                                            Edit Order
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    {returnDraftList.includes(order?.orderType) &&
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.ORDER,
                            roleType.APPROVAL
                        ) && (
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            'Want to return order to Draft'
                                        )
                                    ) {
                                        dispatch(returnOrderToDraft(order.id))
                                    }
                                }}
                            >
                                Return Order to Draft
                            </Button>
                        )}
                </div>
            </div>
        </>
    )
}

export default OrderEditList
