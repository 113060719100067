import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getOneOrder } from '../../store/actions/orderAction'
import { GET_ONE_ORDER } from '../../store/types'
import Layout from '../../components/Layout'
import { Button, Container } from '@mui/material'
import TotalAmount from '../../components/order/TotalAmount'
import { ReviewOrderStyle } from './Order'
import { formTypeList } from '../../constants/form'
import OrderEditList from '../../components/order/OrderEditList'
import OrderLogs from '../../components/order/OrderLogs'

const OrderEdit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { order } = useSelector((state) => state.order)

    useEffect(() => {
        if (id) dispatch(getOneOrder(id))
        return () => {
            dispatch({ type: GET_ONE_ORDER, payload: null })
        }
    }, [dispatch, id])

    return (
        <Layout>
            <Container>
                <div style={{ marginTop: '50px' }} />
                <div className="order-products-box">
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={() => navigate(-1)}
                        style={{ marginBottom: '10px' }}
                    >
                        Back
                    </Button>
                </div>
                <div className="order-product-items">
                    {order?.orderstyles?.map((orderStyle) => (
                        <ReviewOrderStyle
                            key={orderStyle.id}
                            orderStyle={orderStyle}
                            viewType={
                                order?.quoteType === 1
                                    ? formTypeList.EDIT
                                    : formTypeList.READ
                            }
                            orderId={id}
                        />
                    ))}
                </div>
                <OrderEditList order={order} />
                <TotalAmount order={order} createInvoiceButton={true} />
                <OrderLogs order={order} />
            </Container>
        </Layout>
    )
}

export default OrderEdit
